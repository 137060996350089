.bbTagTrigger {
  &Type {
    display: flex;
    gap: 10px;
    align-items: center;

    > span {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
    }
  }

  &TimeFrame {
    padding: 20px;
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    margin-top: 20px;
    display: flex;
    gap: 12px;
    align-items: center;
    border: none;
    box-shadow: var(--shadow-sm-2);
    > span {
      font-size: var(--font-size-lg);
    }
  }

  &Select {
    background-color: var(--color-white);
    width: 169px;
    height: 40px;
    border: none;
    box-shadow: var(--shadow-sm-2);

    &Menu {
      min-width: 300px;
    }
  }

  &FormItem {
    margin: 0 !important;
  }

  &Keyword {
    height: 40px;

    &Chip {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &Weekdays {
    padding: 20px;
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    margin-top: 20px;
    display: flex;
    gap: 32px;
    align-items: center;
    border: none;
    box-shadow: var(--shadow-sm-2);

    > span {
      font-weight: 600;
    }
  }

  &TimeZone {
    background-color: var(--color-white);
    height: 32px;
    border: none;
    box-shadow: var(--shadow-sm-2);
    &Label {
      margin-bottom: 4px;
      font-size: var(--font-size-sm);
      margin-top: 20px;
    }
  }

  .bbAppFiltersFormWrapper {
    div[class*="bbFiltersRow--"] {
      height: 210px;
      overflow: hidden;
    }
  }

  .bbAppFiltersFormWrapperFullHeight {
    div[class*="bbFiltersRow--"] {
      height: 100%;
      overflow: auto;
    }
  }

  .bbViewMoreButton {
    border-radius: var(--border-radius-md);
    margin-top: 20px;
    background-color: var(--color-white);
    border: 1px solid var(--color-surface-5);
    display: flex;
    padding: 6px 10px 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
    color: var(--color-primary-700);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-extrabold);

    svg {
      fill: var(--color-primary-700);
      width: 16px;
      height: 16px;
    }
  }
}

// Time Picker
.bbTimePickerWrapper {
  height: 36px;
  display: flex;
  gap: 12px;
}
.bbTimePeriodWrapper {
  border-radius: var(--border-radius-lg);
  background-color: #f3f3f6;
  display: flex;
}
.bbTimePicker {
  width: 75px;
  padding: 6px 8px;
  border: none;
  border-bottom: 1px solid var(--color-primary-1);
  background-color: var(--color-white);

  & input {
    color: var(--color-primary-1);
  }

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    pointer-events: auto;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;