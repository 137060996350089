:root {
  .bbWorkflowItemForm {
    &Items {
      margin: 14px 0;

      :global {
        .hidden {
          display: none;
        }
      }

      .bbAdvancedUserPermissionsToggle {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--color-primary-900);
        font-weight: var(--font-weight-extrabold);
        margin-top: -4px;
        margin-bottom: 32px;
      }

      .bbAdvancedUserPermissionsSection {
        display: flex;
        gap: 32px;
        align-items: center;

        > div {
          flex: 1;
        }
      }
    }

    &KeywordSelect {
      background: var(--color-surface-2);
      border: none;
      box-shadow: var(--shadow-sm-2);
      width: 220px;
      overflow: hidden;
    }

    &Trigger {
      display: flex;
      align-items: center;
      column-gap: 16px;
      row-gap: 16px;

      @media (max-width: 760px) {
        flex-direction: column;
      }
    }

    &Action {
      display: flex;
      align-items: center;
      column-gap: 16px;
      row-gap: 16px;

      @media (max-width: 760px) {
        flex-direction: column;
      }

      &Header {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &HideWrapper {
        display: flex;
        align-items: center;
        column-gap: 16px;
        row-gap: 16px;
        flex-direction: column !important;
        align-items: flex-start !important;

        .bbWorkflowItemFormActionHide {
          display: contents;
        }
      }

      &SentimentSelect {
        margin-right: -0.5rem;
        margin-bottom: -0.5rem;
      }

      &Label {
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .bbWorkflowItemTrigger {
    margin-bottom: 0 !important;
  }

  .bbWorkflowItemFormHidden {
    display: none;
  }

  .bbWorkflowItemFormWrapper {
    padding: 0;
    padding-top: 20px;

    span[class*="bbFormErrorMessage--"] {
      margin-top: 4px;
      margin-bottom: 8px;
      color: var(--color-danger-1);

      svg {
        fill: var(--color-danger-1);
      }
    }

    :global {
      .ant-form-item-has-error {
        button[class*="bbSimpleSelectTrigger--"] {
          border: 2px solid var(--color-danger-1);
        }

        div[class*="bbInputWrapper--"] {
          border: 2px solid var(--color-danger-1);
        }
      }
    }
  }

  .bbWorkflowItemCardAdditionalAction {
    margin-top: -20px;
  }

  .bbThresholdTriggerActionAccordion {
    padding: 20px;
    margin-top: -8px;
    margin-bottom: 20px;

    div[class*="bbEmailAlertThresholdContainer--"]
      div[class*="bbEmailAlertThresholdConfig--"]
      div[class*="bbEmailAlertThresholdForm--"] {
      div[class*="bbTabPane--"] div[class*="bbTabHeader--"] {
        background-color: var(--color-surface-1);
      }

      div[class*="bbTabPane--"] div[class*="bbTabHeaderActive--"] {
        background-color: var(--color-primary-850);
      }

      div[class*="bbMeridiem--"] label[class*="bbSwitchButton--"] {
        background-color: var(--color-surface-1);
      }

      div[class*="bbMeridiem--"] input[class*="bbSwitch--"]:checked + label {
        background-color: var(--color-primary-850);
      }
    }
  }

  .bbThresholdTriggerActionTitle {
    font-size: var(--font-size-xl);
  }

  .bbThresholdTriggerActionChip {
    padding: 2px 12px;
    height: 22px;
    margin-right: 12px;
    font-size: var(--font-size-sm);
    background: var(--color-secondary-2);
    color: var(--color-on-secondary);
    border-radius: 4999px;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }

  .bbWorkflowItemRemoveButton {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &:hover {
      border-color: var(--color-surface-5);
    }

    &:active,
    &:focus {
      svg {
        fill: var(--color-on-secondary-danger);
      }
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      fill: var(--color-danger-1);
    }
  }

  .bbWorkflowItemRemoveConfirmation {
    margin-top: 20px;
  }
}

:global {
  .ant-form-vertical .ant-form-item {
    flex-direction: column;
    margin: 0 0 24px;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 8px;
  }
}

.bbDisplayBlock {
  display: block;
}

.bbIndicatorSelected {
  position: absolute;
  top: 6px;
  left: 8px;
  margin-right: 2px;
  cursor: help;
  background-color: var(--color-primary-800) !important;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;