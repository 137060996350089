.bbAuthorActionsDropdownTrigger {
  box-shadow: var(--shadow-sm-1);
  margin-left: 4px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;

  svg {
    height: 16px;
  }
}

.bbAuthorActionsDropdownMenu {
  transform: translateX(-50%);
}

.bbAuthorActionsDropdownContent {
  margin: 8px;

  div[class*="bbSentimentBar"] {
    margin-bottom: 12px;
  }

  .bbAuthorAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    font-family: Manrope;
    color: var(--color-on-surface);
    border-radius: var(--border-radius-md);
    cursor: pointer;

    &Left,
    &Right {
      display: flex;
      align-items: center;
    }

    &Active {
      background-color: var(--color-primary-dark);
      color: var(--color-white);

      svg {
        fill: var(--color-white);

        path {
          fill: var(--color-white);
        }
      }
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      fill: var(--color-on-surface-subtle-2);
    }

    &Label {
      width: 150px;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
    }

    &Count {
      padding: 0.5px 5.5px;
      border-radius: var(--border-radius-xxl);
      background: var(--color-surface-4);
      color: var(--color-on-surface-subtle-1);
      text-align: center;
      font-size: var(--font-size-xxs);
      font-weight: var(--font-weight-bold);
    }
  }

  .bbAuthorActionDivider {
    height: 1px;
    background: var(--color-surface-5);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;