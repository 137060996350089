.bbProfilesWrapper {
  flex: 1 0 0;
  width: 100%;

  div[class*="bbTableLoading--"],
  div[class*="bbTableEmpty--"] {
    padding-right: 0;
  }
  div[class*="bbHeaderCol--"] {
    font-size: var(--font-size-md);
    div[class*="bbHeaderColItem--"] {
      flex-wrap: wrap;
    }
  }

  div[class*="bbScrollableTableContainer--"] {
    overflow-x: auto;
  }

  .bbProfilesTableContainer {
    max-height: 585px;
    overflow-y: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .bbProfilesTableContainer::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    -webkit-appearance: none;
  }

  .bbProfilesTableContainer::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-xl);
    background-color: var(--color-surface-8);
    border: 2px solid var(--color-white);
  }

  .bbProfilesTableContainer::-webkit-scrollbar-track {
    background: var(--color-white);
    border-radius: var(--border-radius-xl);
  }

  div.bbProfilesTable {
    position: relative;
    width: 100%;
    font-size: var(--font-size-lg);

    div[class*="bbHeaderRowSticky--"] {
      height: 72px;

      .bbHeaderColIcon {
        display: flex;
        align-items: center;
        margin-left: 6px;
        cursor: help;
        svg {
          width: 14px;
          height: 14px;
          fill: var(--color-on-surface-subtle-2);
        }
      }
    }

    .bbColumnPrefix {
      svg {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-2);
      }
    }

    &Row {
      display: flex;

      .bbColumn {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 16px;
        align-items: center;
        color: var(--color-on-surface);
        border-bottom: 1px solid var(--color-grey-200);
        border-right: 1px solid var(--color-grey-200);
        gap: 4px;
        font-weight: var(--font-weight-medium);

        &NotAvailable {
          color: var(--color-on-surface-subtle-2);
          font-size: var(--font-size-sm);
        }

        &PercentPill {
          margin-left: 0;
        }
      }

      .bbColPlatform {
        height: 72px;
        display: flex;
        align-items: center;
        padding: 16px;
        color: var(--color-blue-900);
        position: relative;
        border-right: 1px solid var(--color-surface-5);
        border-bottom: 1px solid var(--color-surface-5);
        > span {
          padding-right: 4px;
          line-height: 18px;
          color: var(--color-primary-900);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &Colored {
        .bbColumn,
        .bbColPlatform {
          background-color: var(--color-grey-50);
        }
      }
    }

    .bbPlatformIconSmall {
      width: 24px;
      height: 24px;
      margin-right: 12px;

      & > * {
        width: 24px;
        height: 24px;
      }
    }

    .bbPrefixColumn {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 1439px) {
    span[class*="bbPageSizeTitle--"] {
      display: none;
    }
  }
}

.bbProfilesWrapperMultiPages {
  span[class*="bbPageSizeTitle--"] {
    display: none;
  }
}

div.bbProfilesTablePrefix {
  min-height: 40px;
  > div {
    padding: 16px;
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-surface-5);
    border-bottom: 1px solid var(--color-surface-5);
    font-size: var(--font-size-lg);
    background-color: var(--color-secondary-1);
    font-weight: var(--font-weight-extrabold);
    gap: 4px;
    flex-wrap: wrap;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;