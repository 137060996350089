.bbSavedRepliesTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  margin-bottom: 20px;

  &Title {
    margin-top: 20px;
    color: var(--color-primary-900);
    font-size: var(--headline-h6-size);
    font-weight: var(--font-weight-extrabold);
  }

  span[class*="bbTriggerIconWithNoTags"] {
    margin-bottom: 8px;
  }

  button[class*="bbBBTagsTriggerDefault"] {
    top: 0;
    height: 25px;
    top: 0;
    line-height: normal;

    span {
      height: 20px;
    }
  }
}

.bbSavedReplyCreateForm {
  padding: 6px;

  :global {
    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 4px;
    }

    .ant-form-item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .bbSavedReplyCreateFormInput {
    input {
      width: 100%;
    }
  }

  .bbSavedReplyCreateFormInputError {
    border: 2px solid var(--color-danger-1);

    &:hover {
      border: 2px solid var(--color-danger-1);
    }
  }

  &Messages {
    display: flex;
    justify-content: space-between;

    &Title {
      color: var(--color-on-surface);
      font-size: var(--headline-h5-size);
      font-weight: var(--font-weight-extrabold);

      span {
        font-weight: var(--font-weight-medium);
      }
    }

    .bbCollapseAll {
      svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        fill: var(--color-primary-1);
      }
    }
  }

  .bbUsernameSettings {
    &Description {
      color: var(--color-inverted-surface);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-medium);
      line-height: 150%;
      margin-bottom: 20px;

      svg {
        fill: var(--color-on-surface-subtle-2);
      }
    }

    &UnknownTitle {
      color: var(--color-inverted-surface);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      margin-bottom: 12px;

      svg {
        fill: var(--color-on-surface-subtle-2);
      }
    }

    &WordInput {
      margin-top: 12px;
    }
  }

  .bbSavedReplyCreateFormVariation {
    height: 165px;
    width: 100%;
    padding: 8px 16px;
    line-height: 150%;
  }

  &VariationError {
    border: 2px solid var(--color-danger-1);

    &:hover {
      border: 2px solid var(--color-danger-1);
    }
  }

  &Actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;
  }

  .bbFormErrorMessage {
    display: flex;
    gap: 4px;
    margin-top: -6px;
    align-items: center;
    color: var(--color-danger-1);

    div[class*="bbHelp--"] {
      cursor: default;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-danger-1);
    }
  }

  &GenerateActions {
    button {
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
      }
    }

    .bbGenerateBtn {
      background: linear-gradient(to right, rgba(0, 76, 239), rgba(77, 135, 228));

      svg {
        fill: var(--color-on-primary);
      }
    }

    .bbGenerateVariationsError {
      color: var(--color-danger-1);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
      margin-top: -10px;
      margin-bottom: 12px;
    }
  }

  .bbDeleteConfirmation {
    margin: 6px 8px 10px 8px;
  }

  :global {
    .ant-form-item-explain-error {
      color: var(--color-danger-1);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
    }

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }

  .bbSavedReplyBlockIcon {
    svg {
      width: 16px;
      height: 16px;
      margin: 0 5px 0 10px;
      fill: var(--color-on-surface-subtle-2);
    }
  }
}

.bbCommentTagsList {
  & > * {
    display: inline;
  }
}

.bbSavedReplyHeader {
  display: flex;
  margin-top: 8px;
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
  font-size: var(--headline-h3-size);

  &Back {
    padding: 7px;
    margin-right: 8px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;