.bbAIPromptFormHeader {
  display: flex;
  height: 36px;
  margin: 18px 0;

  &Back {
    margin-right: 10px;
    border: none;

    svg {
      width: 16px;
      height: 100%;

      path {
        fill: var(--color-primary-1);
      }
    }
  }

  &Title {
    font-size: var(--headline-h4-size);
    font-weight: var(--font-weight-bold);
    color: var(--color-blue-900);
  }
}

.bbAIPromptFormConfig {
  &Form {
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .bbSetAsDefaultFormItem {
      display: flex;
      margin-top: 20px;
      align-items: baseline;

      > span {
        flex: 1;
        display: inline-flex;
        font-size: var(--font-size-lg);
      }

      .bbSetAsDefaultTitle {
        font-weight: bold;
        padding-left: 8px;
        font-size: var(--font-size-lg);
      }

      .bbSetAsDefaultHelpIcon {
        position: relative;
        top: 2px;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 4px;
          fill: var(--color-grey-600);
          cursor: help;
        }
      }
    }

    .bbPromptSettingsTitle {
      margin-top: 28px;
      margin-bottom: 18px;
      font-size: var(--headline-h5-size);
      font-weight: var(--font-weight-bold);
    }

    &Actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 12px;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;