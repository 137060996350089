.bbReplyBtnWrapper {
  display: flex;
  align-items: center;

  &Mobile {
    width: 100%;

    button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &:hover {
    .bbReplyBtn,
    .bbReplyBtnAction {
      background-color: var(--color-primary-2);
    }
  }
}

.bbReplyBtn {
  min-width: 135px;
  button&WithDropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 8px;
    height: 40px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &Action {
    background-color: var(--color-primary-1);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 8px;
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
    border-left: 1px solid var(--color-white);
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-2);
    }

    &Disabled,
    &Disabled:hover {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: var(--color-primary-1);
    }
    svg {
      width: 12px;
      height: 12px;
      fill: var(--color-white);
    }
  }
}

.bbDropdown {
  min-width: 208px;
  padding: 8px;
  box-shadow: var(--shadow-md-2);
  border-radius: var(--border-radius-lg);

  &Wide {
    width: 320px;
  }

  div&RadioGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 8px;
    align-items: flex-start;
  }
}

.bbApprovalSection {
  display: flex;
  flex-direction: column;

  &List {
    padding-top: 12px;
    max-height: 200px;
    overflow-y: auto;
  }

  &Item {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 6px 12px;
    padding-left: 8px;

    &:hover {
      background-color: var(--color-primary-100);
      .bbItemLabel {
        color: var(--color-primary-700);
        cursor: pointer;
      }
    }
  }

  &Divider {
    display: flex;
    align-items: center;
    span {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-sm);
      padding: 0 5px;
    }
    &Line {
      flex-grow: 1;
      border-top: 1px solid var(--color-surface-5);
    }
  }
  label&Label {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
  }

  span[class*="bbListItemCheckableCheckbox--"] {
    display: flex;
  }

  div[class*="bbListItemCheckable--"] {
    padding-left: 8px !important;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
  }

  div[class*="bbNestedListItem--"] {
    margin-bottom: 0;
  }

  &Header {
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span:last-child {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-medium);
    }
  }

  &Title {
    color: var(--color-inverted-surface);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-extrabold);
  }
}

.bbUserOptionAvatar {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-xxl);
  background: var(--color-white);
  box-shadow: var(--shadow-sm-2);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-extrabold);
  margin-right: 6px;
  color: var(--color-inverted-surface);
}

.bbApprovalButton {
  display: flex;
  align-items: center;
  gap: 8px;

  &Avatar {
    display: flex;
    align-items: center;
  }
  .bbUserAvatar {
    margin-right: -4px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;