.bbPost {
  min-width: 400px;
  overflow: hidden;
  display: flex;

  span[class*="bbPostTabsNotesBadge--"] {
    background-color: var(--color-surface-4);
  }

  &Tab {
    display: flex;
  }

  @media (max-width: var(--screen-sm-min)) {
    flex-direction: column;
    min-width: inherit;

    .bbPostInfo {
      display: flex;

      .bbPostAttachmentContainer {
        margin-top: 20px !important;

        .bbPostImage {
          width: 100px !important;
          height: 100px !important;
        }
      }

      .bbPostDetails {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  .bbPostImageText {
    padding: 10px;
    font-size: var(--font-size-lg);

    &Wrapper {
      padding: 16px;
      background: var(--color-grey-100);
    }
  }

  .bbPostVideoWrapper {
    max-height: 134px;
    overflow: hidden;
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .bbPostVideo {
      width: 100%;
      height: auto;
      border-radius: var(--border-radius-sm);
    }
  }

  .bbPostPDFWrapper {
    border: 1px solid var(--color-grey-300);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .bbPostEmpty {
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .bbPostLoader {
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .bbPostLeft {
    margin-left: 16px;
    margin-right: 16px;
    min-width: 220px;
    overflow: auto;

    .bbPostBrandContainer {
      display: flex;
      align-items: center;
      margin: 10px 10px 0px 0px;

      .bbPostBrandName {
        font-weight: var(--font-weight-bold);
        margin-left: 20px;
        font-size: var(--font-size-2xl);
      }
    }

    .bbPostInfo {
      .bbPostAttachmentContainer {
        .bbPostImage {
          width: 200px;
          height: 200px;
          border-radius: var(--border-radius-md);
        }
      }

      .bbPostAttachmentContainerPortrait {
        .bbPostImage {
          background-color: rgb(var(--surface-5));

          img {
            width: auto;
            margin: 0 auto;
            min-width: auto;
            border-radius: 16px;
          }
        }
      }

      .bbPostDetails {
        .bbPostName {
          margin-top: 10px;
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-bold);
        }

        .bbPostMessage {
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: var(--font-size-md);
          max-width: 200px;
          max-height: 110px;
          overflow: hidden auto;

          @media (max-width: var(--screen-sm-min)) {
            max-height: 200px;
            max-width: inherit;
            padding-right: 8px;
          }
        }
      }
    }
  }

  .bbPostRight {
    position: relative;
    padding: 0 10px 0 20px;
    display: flex;
    min-width: 252px;
    flex-direction: column;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;

    .bbPostPlatform {
      & svg {
        width: 24px;
        height: 24px;
      }
    }

    .bbPostPlatform-youtube {
      & svg {
        width: 29px;
        height: 29px;
      }
    }

    @media (max-width: var(--screen-sm-min)) {
      width: auto;
      margin-left: 16px;
      margin-right: 16px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      background-color: #707070;
      height: 100%;
    }

    .bbPostReactions {
      display: flex;
      margin-top: 8px;

      .bbPostReactionsItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 2px 0 2px;

        .bbPostReactionsIcon {
          display: flex;
        }

        .bbPostReactionsValue {
          font-size: var(--font-size-sm);
        }
      }
    }

    .bbPostLabel {
      font-weight: var(--font-weight-bold);
    }

    .bbPostValue {
      font-size: var(--font-size-sm);
    }

    .bbInsightsContainer {
      max-width: 210px;

      .bbInsight {
        min-width: 90px;
        margin-top: 10px;
        margin-right: 15px;
        display: inline-block;
      }

      .bbInsightLabel {
        font-weight: var(--font-weight-bold);
      }
    }
  }

  &TagListTag {
    height: 26px;
    box-shadow: none;
    padding-right: 24px;
    color: var(--color-on-surface);
    background: var(--color-surface-4);
  }

  &TagListTagIcon {
    top: 50%;
    right: 8px;
    width: 12px;
    height: 12px;
  }

  &TagsDropdownTrigger {
    top: 1px;
    width: 26px;
    height: 26px;
    padding: 6px;
    margin-left: 4px;
    box-shadow: none;
    position: relative;
    border: 1px solid var(--color-surface-5);

    &:hover {
      border: none;
      color: var(--color-on-secondary);
      background: var(--color-secondary-2);
    }

    svg {
      width: 14px;
      height: 14px;
      fill: var(--color-on-surface);
    }
  }

  &TagsDropdownTriggerNoTags {
    width: auto;
    margin-left: 0;
    padding: 5px 10px;
    color: var(--color-primary-1);

    &:hover {
      border: 1px solid transparent;
      color: var(--color-on-secondary);
      background: var(--color-secondary-2);

      svg {
        fill: var(--color-on-surface);
      }
    }

    svg {
      margin-right: 6px;
      fill: var(--color-primary-1);
    }
  }

  .bbPostTagsListingWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    margin-bottom: 28px;
  }

  .bbPostActionIcons {
    display: flex;
    margin-top: auto;
  }

  .bbPostExploreAction {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &Icon {
      display: flex;
      align-items: center;
      margin-right: 8px;

      svg {
        width: 16px;
        height: 16px;
        fill: white;
      }
    }
  }

  .bbPostLinkAction {
    display: flex;
    align-items: center;

    &Icon {
      display: flex;
      align-items: center;
      margin-right: 8px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-primary-btn);
      }
    }
  }

  &Tabs {
    width: 100%;
    &Title {
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &Notes {
      display: flex;
      justify-content: center;

      &Content {
        width: 420px;

        div[class*="bbNotesList--"] {
          max-height: 460px;
        }
      }

      &Badge {
        display: flex;
        padding: 1px 7px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: var(--border-radius-xxl);
        background-color: var(--color-surface-2);
        color: var(--color-on-surface-subtle-1);
        text-align: center;
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-extrabold);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;