@category-tag-color: #7723ff;
@category-archive-color: #1d9aaa;

@category-tag-color-light: #ebdffe;
@category-hide-color-light: #fbd8d8;
@category-reply-color-light: #dae5fa;
@category-other-color-light: #f1f1f1;
@category-archive-color-light: #cfecf0;
@category-email_alert-color-light: #f2e0d0;

.bbOverview {
  display: flex;
  flex-direction: column;
  gap: 60px;

  div[class*="bbHeader--"] {
    display: none;
  }

  &TopWidgets,
  &BottomWidgets {
    display: flex;
    gap: 40px;

    > div {
      flex: 1;
    }
  }

  &BottomWidgets {
    gap: 28px;
  }

  &Summary {
    display: flex;
    flex-direction: column;
    gap: 20px;

    div[class*="bbManagedSummaryCardDisabled--"] {
      margin: unset;
    }

    div[class*="bbManagedSummary--"] {
      flex: 1 !important;
      max-width: unset !important;
    }

    &Title {
      color: var(--color-inverted-surface);
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      letter-spacing: 0.2px;
      display: flex;
      gap: 8px;

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        fill: var(--color-gradient-1);
      }
    }

    button.bbOverviewSummaryAction,
    button.bbOverviewSummaryAction:hover,
    button.bbOverviewSummaryAction:disabled {
      background-color: var(--color-surface-2);
      width: fit-content;
      box-shadow: var(--shadow-sm-2);

      > span {
        display: flex;
        gap: 6px;
        align-items: center;
      }
      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
      }
    }
  }

  &Workflows {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .bbBadgeNumber {
      text-align: center;
      padding: 1px 7px;
      font-size: var(--font-size-sm);
      color: var(--color-on-primary-dark);
      font-weight: var(--font-weight-extrabold);
      border-radius: var(--border-radius-xl);
      background: var(--color-primary-dark);
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 18px;
    }

    .bbBadgeNumberInactive {
      background: var(--color-surface-4);
      color: var(--color-on-surface-subtle-1);
    }

    &Title {
      color: var(--color-inverted-surface);
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      letter-spacing: 0.2px;
      display: flex;
      justify-content: space-between;

      > div {
        gap: 8px;
        display: flex;
        align-items: center;
      }

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        fill: var(--color-gradient-1);
      }

      .bbOverviewWorkflowsSubTitle {
        color: var(--color-on-surface);
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-medium);
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    &List {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &Item {
        width: 100%;
        height: 52px;
        cursor: pointer;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        color: var(--color-on-surface);
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
        box-shadow: var(--shadow-sm-2);
        border-radius: var(--border-radius-xl);
        background-color: var(--color-surface-2);

        > div {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .bbWorkflowCategoryIcon {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: var(--border-radius-xxl);
          box-shadow: var(--shadow-sm-2);
          background: var(--color-surface-2);

          svg {
            width: 16px;
            height: 16px;
          }
        }

        .bbWorkflowBadgeNumberContainer {
          svg {
            width: 16px;
            height: 16px;
            fill: var(--color-primary-1);
          }
        }
      }

      .bbOverviewWorkflowsListItem-tag {
        background: linear-gradient(to right, @category-tag-color-light, var(--color-surface-2));

        .bbWorkflowCategoryIcon svg {
          fill: @category-tag-color;
        }
      }

      .bbOverviewWorkflowsListItem-archive {
        background: linear-gradient(
          to right,
          @category-archive-color-light,
          var(--color-surface-2)
        );

        .bbWorkflowCategoryIcon svg {
          fill: @category-archive-color;
        }
      }

      .bbOverviewWorkflowsListItem-reply {
        background: linear-gradient(to right, @category-reply-color-light, var(--color-surface-2));

        .bbWorkflowCategoryIcon svg {
          fill: var(--color-primary-1);
        }
      }

      .bbOverviewWorkflowsListItem-hide {
        background: linear-gradient(to right, @category-hide-color-light, var(--color-surface-2));

        .bbWorkflowCategoryIcon svg {
          fill: var(--color-danger-1);
        }
      }

      .bbOverviewWorkflowsListItem-email_alert {
        background: linear-gradient(
          to right,
          @category-email_alert-color-light,
          var(--color-surface-2)
        );

        .bbWorkflowCategoryIcon svg {
          fill: var(--color-warning-600);
        }
      }

      .bbOverviewWorkflowsListItem-other {
        background: linear-gradient(to right, @category-other-color-light, var(--color-surface-2));

        .bbWorkflowCategoryIcon svg {
          fill: var(--color-on-surface-subtle-2);
        }
      }

      .bbOverviewWorkflowsListItemInactive {
        background: var(--color-surface-2);
      }
    }

    button.bbOverviewWorkflowsAction,
    button.bbOverviewWorkflowsAction:hover,
    button.bbOverviewWorkflowsAction:disabled {
      background-color: var(--color-surface-2);
      width: fit-content;
      box-shadow: var(--shadow-sm-2);

      > span {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-1);
      }
    }
  }

  &CardAction {
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-gradient-1);
    }
  }

  &News {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--border-radius-xl);
    background: var(--color-surface-2);
    box-shadow: var(--shadow-sm-1);

    &Container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &Title {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--color-inverted-surface);
      font-size: var(--headline-h4-size);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      letter-spacing: 0.2px;
      svg {
        width: 24px;
        height: 24px;
        fill: var(--color-gradient-1);
      }
    }

    &Header {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--color-gradient-1);
        path {
          fill: var(--color-gradient-1);
        }
      }

      &Text {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        color: var(--color-on-surface-subtle-1);
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-medium);

        span:first-child {
          color: var(--color-inverted-surface);
          font-size: var(--font-size-xl);
          font-weight: var(--font-weight-extrabold);
          line-height: 120%;
          letter-spacing: 0.16px;
        }
      }
    }

    &Section {
      max-height: 480px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      padding-right: 10px;
    }

    &List {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &Footer {
      color: var(--color-inverted-surface);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
      display: flex;
      gap: 2px;
    }

    a {
      color: var(--color-primary-1);
      font-weight: var(--font-weight-extrabold);
    }
  }

  &ComingSoon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    &Icon {
      display: flex;
      padding: 6px;
      align-items: center;
      gap: 10px;
      border-radius: var(--border-radius-xxl);
      background: var(--color-primary-150);

      svg {
        width: 14px;
        height: 14px;
        fill: var(--color-gradient-1);
      }
    }

    &Title {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      color: var(--color-inverted-surface);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-extrabold);
      line-height: 120%;
      letter-spacing: 0.16px;
    }
  }
}

@media (max-width: 1400px) {
  .bbOverview {
    &TopWidgets {
      flex-direction: column;
      gap: 32px;
      > div {
        flex: initial;
      }
    }

    &Summary {
      div[class*="bbManagedSummary--"] {
        min-width: 270px;
      }
    }

    &Workflows {
      &List {
        flex-direction: row;
        flex-wrap: wrap;
        gap: unset;
        row-gap: 12px;
        margin-left: -16px;
        margin-right: -16px;

        &ItemWrapper {
          flex: 0 0 50%;
          max-width: 50%;
          position: relative;
          min-height: 1px;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .bbOverview {
    &Workflows {
      &Title {
        flex-direction: column;
        gap: 12px;
      }

      &List {
        flex-direction: row;
        flex-wrap: wrap;
        gap: unset;
        row-gap: 12px;
        margin-left: 0;
        margin-right: 0;

        &ItemWrapper {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .bbOverview {
    gap: 32px;

    div[class*="bbHeader--"] {
      display: block;
    }

    &BottomWidgets {
      flex-direction: column;
      gap: 32px;
      > div {
        flex: initial;
      }

      .bbOverviewNewsSection {
        max-height: 150px;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;