.bbAlertsTable {
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &Description {
    margin-bottom: 32px;
    font-size: var(--font-size-md);
  }

  &Col {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-primary);
    border-right: 1px solid var(--color-surface-5);

    &:last-child {
      border-right: none;
    }

    &Icon {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin: 0 6px 0 4px;

      svg {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-2);
      }
    }
  }

  .bbAlertsTableRow {
    display: flex;

    font-size: var(--font-size-lg);
    border-bottom: 1px solid var(--color-surface-5);
    background-color: var(--color-grey-50);

    &:last-child {
      border-bottom: none;
    }

    &Even {
      background-color: transparent;
    }

    &Cell {
      display: flex;
      align-items: center;
      padding: 18px;
      border-right: 1px solid var(--color-surface-5);
      width: 100%;

      &:last-child {
        border-right: none;
      }
    }

    &Platforms {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 6px;
      align-items: center;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &Notification {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      &Name {
        h3 {
          margin: 0 0 4px 0;
          padding: 0;
          font-size: var(--font-size-sm);
          font-weight: var(--font-weight-bold);
          color: var(--color-text-primary);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: var(--color-primary-1);
          }
        }

        p {
          margin: 0;
          padding: 0;
          font-size: var(--font-size-sm);
          color: var(--color-on-surface-subtle-1);
        }
      }
    }

    &PostPreview {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: var(--border-radius-md);
      cursor: pointer;
      margin-right: 8px;

      &Placeholder {
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        width: 40px;
        height: 40px;
        font-size: var(--font-size-xxs);
        background-color: var(--color-surface-5);
        color: var(--color-grey-700);
        border-radius: var(--border-radius-md);
        cursor: pointer;
        margin-right: 8px;
        span {
          white-space: nowrap;
          transform: scale(0.8);
        }
      }
    }
  }

  &Chip {
    cursor: pointer;
    &Shadow {
      box-shadow: var(--shadow-sm-2);
    }
  }

  .bbAlertsTableContextMenu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-color: var(--color-surface-5);

    &:active,
    &:hover {
      border-color: transparent;
    }

    svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }

  &ContextMenuWrapper {
    min-width: 180px;
  }
}

.bbMoreItems {
  font-size: var(--font-size-xs);
  color: var(--color-on-surface-subtle-1);
  margin-left: 4px;

  &Tooltip {
    cursor: help;
  }
}

.bbArchiveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-on-primary-btn);
  }
}

.bbEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 180px 0;
  gap: 16px;
  &Title {
    font-size: var(--headline-h3-size);
    font-weight: var(--font-weight-bold);
    color: var(--color-text-primary);
    margin: 0;
    padding: 0;
  }

  &Description {
    font-size: var(--font-size-lg);
    color: var(--color-text-primary);
    margin: 0 0 12px;
    padding: 0;
  }

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-white);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;