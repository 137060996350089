.bbMessageCardOffsetLeft {
  margin-left: 24px;
}

.bbMessageCardOffsetRight {
  margin-right: 24px;
}

.bbPrivateMessageCardUser {
  color: var(--color-blue-900);
  background: var(--color-grey-100);
  flex-shrink: 0;
}

.bbPrivateMessageContainer {
  display: flex;
  align-items: flex-end;

  .bbPrivateMessageWrapper {
    display: flex;
    align-items: center;

    .bbPrivateMessageCardTags {
      margin-left: 8px;
      display: flex;
      align-items: center;

      .bbPrivateMessageCardBBTags {
        margin-left: 4px;
      }
    }
  }
}

.bbPrivateMessageContainer-left {
  justify-content: flex-start;
}

.bbPrivateMessageContainer-right {
  justify-content: flex-end;
}

.bbPrivateMessageContent {
  height: auto;
  display: flex;
  margin-top: 4px;
  min-height: 48px;
  max-width: 670px;
  padding: 8px 16px;
  white-space: pre-line;
  flex-direction: column;
  justify-content: center;
  color: var(--color-blue-900);
  font-size: var(--font-size-xl);

  & > * {
    margin: 0;
  }

  .bbAttachmentReplyPhrase {
    border: none;
    margin: 0 8px;
    color: var(--color-grey-700);
  }
}

.bbStoryPreviewMessage {
  margin-left: 4px;
  color: var(--color-grey-700);
  font-size: var(--font-size-sm);
}

.bbPrivateMessageContentTransparent {
  background: transparent;
  padding: 0;
}

.bbPrivateMessageContentRegular.bbMessageCardTextual-default {
  margin-left: 8px;
  background: var(--color-secondary-1);
}

.bbPrivateMessageContentRegular.bbMessageCardTextual-highlighted {
  margin-right: 8px;
  color: var(--color-white);
  background: linear-gradient(to right, rgba(0, 76, 239), rgba(77, 135, 228));

  audio {
    color: var(--color-white);
  }
}

.bbPrivateMessageContentRegular {
  p {
    min-width: 20px;
    min-height: 20px;
  }
}

.bbMessageCardAttachment-left {
  justify-content: flex-start;
  margin-left: 8px;
}

.bbMessageCardAttachment-right {
  justify-content: flex-end;
  margin-right: 8px;
}

.bbPrivateMessageReplyDateTime-left {
  margin-left: 32px;
  justify-content: flex-start;
}

.bbPrivateMessageReplyDateTime-right {
  margin-right: 32px;
  justify-content: flex-end;
}

.bbMessageCardPosition-left {
  border-radius: 16px;
  border-bottom-left-radius: 0px;
}

.bbMessageCardPosition-right {
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  color: var(--text-on-primary);
}

.bbMessageCardBackground-default {
  background: var(--color-white);
  box-shadow: var(--shadow-sm-2);
}

.bbMessageCardBackground-highlighted {
  background: var(--color-primary-100);
}

.bbMessageCardSize-md {
  font-size: var(--font-size-lg);
}

.bbMessageCardSize-lg {
  font-weight: 700;
  font-size: var(--font-size-xl);
}

.bbMessageCardSentiment {
  border-top-width: 4px;
}

.bbMessageCardSentiment.bbMessageCardSentiment-neutral {
  border-top-color: var(--color-grey-300);
}

.bbMessageCardSentiment.bbMessageCardSentiment-positive {
  border-top-color: var(--color-green-600);
}

.bbMessageCardSentiment.bbMessageCardSentiment-negative {
  border-top-color: var(--color-red-700);
}

.bbMessageCardSentiment.bbMessageCardSentiment-user_tags {
  border-top-color: var(--color-blue-900);
}

.bbMessageCardSentiment.bbMessageCardSentiment-universally_harmful {
  border-top-color: var(--color-red-700);
}

.bbMessageCardSentiment.bbMessageCardSentiment-sensitive {
  border-top-color: #ea9999ff;
}

.bbMessageCardSentiment.bbMessageCardSentiment-non_harmful {
  border-top-color: #bdcae0;
}

.bbMessageCardSentiment.bbMessageCardSentiment-hidden {
  border-top-color: #383e4bff;
}

.bbPrivateMessageCardFallbackAttachment {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-primary-1);

  &:hover {
    text-decoration: none;
  }
}

.bbPrivateMessageError {
  margin-top: 4px;
  display: flex;
  font-size: var(--font-size-sm);
  color: var(--color-danger-1);

  svg {
    top: 2px;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    position: relative;
    fill: var(--color-danger-1);
  }
}

.bbPrivateMessageErrorLeft {
  margin-left: 8px;
}

.bbPrivateMessageErrorRight {
  margin-right: 8px;
}

.bbPrivateMessageCardTags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  align-items: center;
  gap: 4px;
  margin-left: 8px;
}

.bbPrivateMessageCardTagsWithAuthor {
  margin-left: 28px;
}

.bbPrivateMessagePostLink {
  gap: 6px;
  display: flex;
  font-weight: var(--font-weight-bold);
  margin: 0 15px;
  align-items: center;
  color: var(--color-blue-900);
  background-color: var(--color-white);
  color: var(--color-primary-1);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-surface-5);

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-primary-1);
  }
}

.bbAudioTrack-right {
  audio::-webkit-media-controls-panel {
    background: linear-gradient(to right, rgba(0, 76, 239), rgba(77, 135, 228));
  }

  audio::-webkit-media-controls-mute-button,
  audio::-webkit-media-controls-volume-slider {
    display: none !important;
  }

  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    text-shadow: none;
    box-shadow: none;
  }

  audio::-webkit-media-controls-timeline {
    color: var(--color-white);
  }

  audio {
    background: linear-gradient(to right, rgba(0, 76, 239), rgba(77, 135, 228));
  }
}

.bbAudioTrack-left {
  audio::-webkit-media-controls-panel {
    background-color: var(--color-primary-100);
  }

  audio::-webkit-media-controls-mute-button,
  audio::-webkit-media-controls-volume-slider {
    display: none !important;
  }

  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    color: var(--color-blue-850);
  }

  audio::-webkit-media-controls-timeline {
    color: var(--color-blue-850);
    background-color: var(--color-primary-100);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;