.bbPostPreviewTooltipBody {
  padding: 6px 4px;
  color: var(--color-blue-900);

  .bbHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .bbTitle {
      display: flex;

      .bbPlatform {
        margin-right: 8px;

        & svg {
          width: 24px;
          height: 24px;
        }

        &-youtube {
          svg {
            width: 29px;
            height: 29px;
          }
        }
      }

      .bbAuthor {
        line-height: 21px;
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
      }
    }

    .bbSubtitle {
      color: var(--color-grey-700);
      font-size: var(--font-size-sm);
      line-height: 18px;
    }
  }

  .bbMessage {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin-bottom: 12px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .bbPreview {
    max-height: 200px;
    margin-bottom: 12px;
    border-radius: var(--border-radius-sm);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    .bbPicture,
    .bbVideo {
      width: 100%;
      height: auto;
    }

    .bbMediaType {
      position: absolute;
      display: flex;
      align-items: center;
      background-color: var(--color-white);
      color: var(--color-grey-700);
      padding: 1px 6px;
      border-radius: var(--border-radius-lg);
      box-shadow: var(--shadow-sm-1);
      right: 12px;
      bottom: 12px;

      .bbIcon {
        display: flex;
        margin-right: 4px;

        svg {
          width: 12px;
          height: 12px;
          fill: var(--color-grey-700);
        }
      }

      .bbLabel {
        color: var(--color-grey-700);
        font-size: var(--font-size-sm);
      }
    }

    .bbType {
      position: absolute;
      background-color: var(--color-white);
      color: var(--color-grey-700);
      padding: 1px 6px;
      border-radius: var(--border-radius-lg);
      box-shadow: var(--shadow-sm-1);
      left: 12px;
      bottom: 12px;
    }
  }

  .bbPostIdWrapper {
    margin-bottom: 12px;
    font-size: 13px;

    .bbPostId {
      margin-left: 12px;
      font-weight: var(--font-weight-bold);
    }
  }
}

.bbPostTooltipFooter {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-top: 12px;

  div[class*="bbDivider--"] {
    height: 44px;
  }

  &Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
  }

  &Metric {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    justify-content: space-between;

    > div {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &Text {
      color: var(--color-on-surface-subtle-1);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-medium);
    }

    &Value {
      color: var(--color-on-surface);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
    }
  }

  svg {
    width: 12px;
    height: 12px;
    fill: var(--color-on-surface-subtle-2);
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;