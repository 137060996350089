@trigger-btn-size: 16px;

.bbSharePostWrapper {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 20px;
  height: 28px;
  width: 28px;
  margin-left: 6px;
  justify-content: center;
  border: 1px solid var(--color-notApplicable);

  .bbSharePostButton {
    display: flex;
    padding: 0;
    width: @trigger-btn-size;
    height: @trigger-btn-size;
    border: none;
    box-shadow: none;
    background: transparent;
    position: relative;
    align-items: center;
  }
}

.bbSharePostSelectorModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: var(--color-white);

  &Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Title {
      color: var(--color-blue-900);
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: 0.24px;
    }

    &Actions {
      display: flex;
      align-items: center;

      &CloseWrapper {
        height: 20px;
        margin: 10px 10px 10px 20px;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &Content {
    height: 500px;
    width: 100%;
    overflow: auto;

    &List {
      padding-left: 0;
    }

    &Loading {
      display: flex !important;
      justify-content: center;
    }
  }
}

.bbDisplayBlock {
  display: block;
}

.bbDropdownMenuClassName {
  overflow-x: hidden;
  overflow-y: auto;
  height: 224px;
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;