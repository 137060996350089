.bbMessageCardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  border-radius: 4px;
}

.bbMesageCardAvatar {
  color: var(--color-primary-900);
}

.bbMessageCardHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.bbMessageCardHiddenIcon {
  margin-top: 6px;
  align-self: flex-start;
  margin-right: 8px;
}

.bbMessageCardHiddenIconWithTooltip {
  cursor: help;
}

.bbMessageCardText {
  margin: 0;
  margin-top: 6px;
  padding-bottom: 6px;
  word-break: break-word;
}

.bbMessageActionsFooterAssignUser {
  display: flex;
  flex: 1;
  margin-right: 12px;

  & > * {
    flex: 1;
  }

  & div,
  button {
    height: 100%;
  }
}

.bbMessageActionsFooterAssignUserTrigger {
  width: 100%;
  background: white;

  &:hover {
    background: var(--color-secondary-2);
  }
}

.bbMessageActionsFooterAssignUserTriggerSelected {
  background: var(--color-primary-200);
}

.bbTranslateBtn {
  padding-left: 0 !important;
}

.bbMessageCardRightActionsWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    bottom: 1px;
    position: relative;
  }
}

.bbMessageCardHeader {
  flex: 1;
  font-size: var(--font-size-sm);

  &Author {
    font-weight: bold;
  }

  &Date {
    overflow: hidden;
    margin-left: 4px;
    flex-basis: 0;
    flex-grow: 1;
    white-space: pre-line;
    color: var(--color-grey-700);
    font-weight: normal;
  }

  &Phrase {
    font-weight: normal;
    color: var(--color-grey-700);
  }
}

.bbMessageCard {
  flex-basis: 0;
  flex-grow: 1;
  white-space: pre-line;
  margin-bottom: 4px;
  margin-left: 8px;
  border-radius: 8px;
  padding: 12px;
  font-size: var(--font-size-lg);
  color: var(--color-primary-900);

  &Mobile {
    margin-left: 0;
  }
}

.bbMessageActionsDropdown {
  box-shadow: none !important;
  border: none;
}

.bbMessageCardActions {
  display: flex;
  margin-top: 1;
  margin-bottom: 10px;
  border-top: 1px solid var(--color-surface-5);
  padding-top: 16px;

  .bbMessageActionButtonClass {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: var(--color-primary-850);

    & > * {
      fill: var(--color-primary-850);
      color: var(--color-primary-850);
    }
  }

  .bbMessageActionTriggerClass {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: var(--color-primary-850);
    border: 1px solid var(--color-surface-5);
    padding: 5px 10px;
    min-height: 32px;
    box-shadow: var(--shadow-sm-1);
    white-space: nowrap;

    &hover {
      background-color: var(--color-primary-200);
    }

    & > * {
      fill: var(--color-primary-900);
      color: var(--color-primary-900);

      svg {
        fill: var(--color-primary-900);
        color: var(--color-primary-900);
      }
    }
  }
}

.bbMessageActionFlex1 {
  display: flex;
  margin-right: 12px;
  flex: 1;
}

.bbMessageActionFlex2 {
  display: flex;
  margin-right: 12px;
  flex: 2;
}

.bbMessageCardPosition-left {
  border-radius: 16px;
  border-bottom-left-radius: 0px;
}

.bbMessageCardPosition-right {
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  color: var(--text-on-primary);
}

.bbMessageCardBackground-default {
  background: var(--color-white);
  box-shadow: var(--shadow-sm-2);
}

.bbMessageCardBackground-highlighted {
  background: var(--color-primary-100);
}

.bbMessageCardSize-md {
  font-size: var(--font-size-lg);
}

.bbMessageCardSize-lg {
  font-weight: 700;
  font-size: var(--font-size-xl);
}

.bbMessageCardSentiment {
  border-top-width: 4px;
}

.bbMessageCardSentiment.bbMessageCardSentiment-neutral {
  border-top-color: var(--color-grey-300);
}

.bbMessageCardSentiment.bbMessageCardSentiment-positive {
  border-top-color: var(--color-green-600);
}

.bbMessageCardSentiment.bbMessageCardSentiment-negative {
  border-top-color: var(--color-red-700);
}

.bbMessageCardSentiment.bbMessageCardSentiment-user_tags {
  border-top-color: var(--color-blue-900);
}

.bbMessageCardSentiment.bbMessageCardSentiment-universally_harmful {
  border-top-color: var(--color-red-700);
}

.bbMessageCardSentiment.bbMessageCardSentiment-sensitive {
  border-top-color: #ea9999ff;
}

.bbMessageCardSentiment.bbMessageCardSentiment-non_harmful {
  border-top-color: #bdcae0;
}

.bbMessageCardSentiment.bbMessageCardSentiment-hidden {
  border-top-color: #383e4bff;
}

.bbMessageCardActionsDropdownWrapper {
  .bbMessageCardActionsDropdown {
    background-color: white;
  }

  & div,
  span,
  button {
    height: 38px;
    display: block;
  }

  & button {
    border: 1px solid var(--color-surface-5);
  }
}

.bbAudioMessageContent {
  p {
    min-width: 20px;
    min-height: 20px;
  }
}

.bbAudioMessageContent {
  p {
    min-width: 20px;
    min-height: 20px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;