.bbSuggestedRepliesCarousel {
  width: 100%;
  color: var(--color-on-surface);
  font-weight: var(--font-weight-medium);

  &Mobile {
    margin-top: 20px;
  }

  &Trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    background-color: var(--color-white);
    border-radius: var(--border-radius-xl);
    border: none;
    background:
      linear-gradient(#fff, #fff) padding-box,
      linear-gradient(90deg, #74aaf7 0%, #94baf8 100%) border-box;
    border: 2px solid transparent;

    &Mobile {
      padding: 10px 20px;
    }

    &Label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-medium);

      svg {
        width: 16px;
        height: 16px;
        fill: #dabb1c;
      }
    }

    &Pager {
      display: flex;
      align-items: center;
      gap: 8px;

      &Button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        background: none;
        border: none;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: var(--color-primary-1);
        }
      }

      &Page {
        font-size: var(--font-size-lg);
      }
    }
  }

  &AllSuggestions {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    background-color: transparent;
    border: none;
    color: var(--color-primary-1);
    font-size: var(--font-size-md);
    cursor: pointer;
    font-weight: var(--font-weight-bold);

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
      transition: transform 0.2s ease;
    }

    &Open svg {
      transform: rotate(180deg);
    }
  }

  &Suggestions {
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-lg-2);
    animation: fadeAndGrow 0.2s ease-out;
    overflow: hidden;

    &List {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      max-height: 245px;
      overflow-y: auto;
    }

    @keyframes fadeAndGrow {
      from {
        opacity: 0;
        transform: scale(0.95);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    &Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      gap: 8px;
      border-radius: var(--border-radius-md);
      border: 1px solid var(--color-surface-5);
      cursor: pointer;

      &Text {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
        color: var(--color-primary-1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;