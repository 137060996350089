.bbCommentZendeskTicketDetails {

  &Field {
    margin-bottom: 12px;

    &Label {
      font-size: var(--font-size-sm);
    }
  }

  &Subject {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-extrabold);
  }

  &Description {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    line-break: anywhere;
  }

  &Section {
    margin-bottom: 20px;
    border-top: 1px solid var(--color-surface-5);

    &Title {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-extrabold);
      margin-top: 10px;
    }
  }

  &User {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    &Name {
      font-size: var(--font-size-lg);
    }

    &LinkButton {
      svg {
        height: 16px;
        fill: var(--color-primary-700);
        margin-right: 6px;
      }
    }

    &LinkedButton {
      color: var(--color-green-500);

      svg {
        height: 16px;
        fill: var(--color-green-500);
        margin-right: 6px;
      }
    }
  }

  &InternalComments {
    &Comment {
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-medium);
      margin-top: 12px;

      &Author {
        font-weight: var(--font-weight-extrabold);
        margin-right: 4px;
      }
    }

    &NewComment {
      display: flex;
      gap: 9px;

      svg {
        width: 16px;
        fill: var(--color-white);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;