.bbSources {
  position: relative;
  margin-top: 40px;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 20px;
    gap: 15px;

    .bbAddSourceBtnIcon {
      width: 12px;
      height: 12px;
      fill: var(--color-white);
      position: relative;
      bottom: 2px;
      margin-right: 6px;
    }
  }

  .bbTableRow {
    .bbColumn {
      font-size: var(--font-size-lg);
      font-weight: 400;
      display: flex;
      padding: 8px 18px;
      height: 79px;
      align-items: center;
      color: var(--color-blue-900);

      .bbSourceActions {
        display: flex;
        align-items: center;
        gap: 12px;

        .bbSourceActionBtn {
          padding: 8px;

          svg {
            width: 16px;
            height: 16px;
            fill: var(--color-primary-1);
          }
        }
      }

      .bbStatusTag {
        padding: 4px 8px;
        align-items: center;
        display: inline-flex;
        font-size: var(--font-size-sm);

        svg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          fill: var(--color-white);
        }

        &-active {
          color: var(--color-green-700);

          svg {
            fill: var(--color-green-700);
          }
        }
      }
    }

    .bbSourceColumn {
      font-weight: var(--font-weight-bold);
    }

    .bbAssetColumn {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .bbActionsColumn {
      justify-content: flex-end;
    }

    &Colored {
      background-color: var(--color-grey-50);
    }
  }
}

.bbSourceModal {
  &PlatformIconWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 22px;
    right: 70px;
    font-size: 12px;
    color: #676f85;
  }

  &PlatformIcon {
    width: 24px;
    height: 24px;
    margin-left: 5px;
  }

  &Select {
    width: 100%;
  }

  &FormItem {
    [role="alert"] {
      color: var(--color-danger-1);
    }
  }

  &SourceLabelInputPrefix {
    display: flex;
    height: 20px;
    margin-right: 8px;

    svg {
      fill: #858ea6;
    }
  }

  &Footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;