.bbNotes {
  &TabTitle {
    display: flex;
    gap: 8px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-extrabold);
    align-items: center;
  }

  &Count {
    padding: 0.5px 5.5px;
    height: fit-content;
    display: flex;
    border-radius: var(--border-radius-xxl);
    background-color: var(--color-surface-4);
    color: var(--color-on-surface-subtle-1);
    text-align: center;
    font-size: var(--font-size-xxs);
    font-weight: var(--font-weight-bold);
  }
  textarea.bbNotesInput {
    background: transparent;
    border: none;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-inverted-surface);
    line-height: 150%;
    padding: 0;

    &:hover {
      border: none;
    }
  }

  &Input {
    &Wrapper {
      padding: 8px;
      border-radius: var(--border-radius-md);
      background-color: var(--color-surface-4);
      display: flex;
      flex-direction: column;
      gap: 10px;

      div[class*="bbEmojiPicker--"] {
        border: none;

        button[class*="bbEmojiButton--"] {
          width: 20px;
          height: 20px;
          background: transparent;

          svg {
            fill: var(--color-primary-850);
          }
        }
      }

      button[class*="bbButton--"] {
        font-size: var(--font-size-md);
      }
    }

    &Footer {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;
    }
  }

  &Chip {
    background-color: var(--color-white);
  }

  &List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    margin-top: 12px;
    max-height: 260px;
    overflow-y: auto;
  }

  &Item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    &Header {
      display: flex;
      align-items: center;
      height: 32px;
      gap: 8px;
      justify-content: space-between;
      align-self: stretch;

      &Left {
        display: flex;
        gap: 8px;
        align-items: center;

        .bbNotesItemHeaderImg {
          display: flex;
          width: 26px;
          height: 26px;
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius-xxl);
          background: var(--color-white);
          box-shadow: var(--shadow-sm-2);
          font-size: var(--font-size-xxs);
        }
      }

      &Title {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-primary-850);
      }
    }

    &Title {
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-extrabold);
      color: var(--color-inverted-surface);
    }

    &Time {
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-bold);
      color: var(--color-on-surface-subtle-1);
    }

    &Message {
      display: flex;
      align-items: flex-start;
      gap: 3px;
      align-self: stretch;
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-medium);
      color: var(--color-inverted-surface);
    }

    &Action {
      cursor: pointer;
      display: none;
      padding: 8px;
      height: 32px;
      align-items: center;
      border-radius: var(--border-radius-md);

      &Hovered {
        display: flex;
      }

      &:hover {
        background-color: var(--color-primary-200);
      }
    }

    &Delete {
      display: flex;
      width: 160px;
      padding: 8px;
      align-items: center;
      gap: 6px;
      color: var(--color-inverted-surface);
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-bold);
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--color-on-surface-subtle-2);
      }
    }
  }

  &MentionList {
    display: flex;
    width: 320px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    position: fixed;
    background-color: var(--color-white);
    box-shadow: var(--shadow-md-2);
    border-radius: var(--border-radius-lg);
    z-index: 1000;
    max-height: 218px;
    overflow-y: auto;
    cursor: pointer;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-inverted-surface);

    li {
      padding: 6px 8px;
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .bbMentionOptionAvatar {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius-xxl);
      background: var(--color-white);
      box-shadow: var(--shadow-sm-2);
      font-size: var(--font-size-xxs);
    }
  }

  &UserMention {
    display: inline-flex;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--color-primary-700);
    background-color: var(--color-primary-150);
    border-radius: var(--border-radius-sm);

    &Disabled {
      background-color: var(--color-surface-4);
      color: var(--color-on-surface-subtle-1);
    }
  }

  &CurrentUserMention {
    background-color: #fff2bd;
    color: var(--color-inverted-surface);
  }

  &History {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    &Loading {
      justify-content: center;
      align-items: center;
      overflow: unset;
    }

    &Item {
      display: flex;
      flex-direction: column;
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-medium);
      color: var(--color-inverted-surface);

      &Bold {
        font-weight: var(--font-weight-extrabold);
      }

      &Italic {
        font-style: italic;
      }
    }

    &Date {
      color: var(--color-on-surface-subtle-1);
    }

    &List {
      max-height: 360px;
      overflow: auto;
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    &Info {
      display: flex;
      padding: 1px 6px;
      align-items: center;
      gap: 4px;
      color: var(--color-primary-dark);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-bold);
      border-radius: var(--border-radius-sm);
      background-color: var(--color-primary-150);

      svg {
        width: 12px;
        height: 12px;
        fill: var(--color-primary-dark);
      }
    }
  }
}

.bbNotesTabsContainer {
  div[class*="bbTabHeadersContainer--"] {
    margin-bottom: 12px;
    width: 100%;
  }
}

.bbNotesTabsContainer {
  div[class*="bbTabHeadersContainer--"] {
    margin-bottom: 12px;
    width: 100%;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;