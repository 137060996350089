.bbTagsCustomDropdown {
  display: inline-flex;
}

.bbTagsCustomDropdownCustomClass {
  height: 26px;
}

.bbTagsCustomDropdownDefaultClass {
  line-height: 32px;
}

.bbTagsDropdownMenu {
  padding: 8px;
  min-width: 230px;
  min-width: 380px;

  @media (min-width: 640px) {
    min-width: 380px;
  }
}

.bbTagsDropdownContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &Title {
    margin-left: 8px;
    font-weight: bold;
    line-height: 20px;
    font-size: var(--headline-h5-size);
  }
}

.bbBBTagsTrigger {
  position: relative;
  outline: none;
  box-shadow: none;
  color: var(--color-primary-700);

  &:hover {
    background-color: var(--color-primary-200);
  }

  svg {
    fill: var(--color-primary-700);
  }
}

.bbBBTagsTriggerDefault {
  top: 2px;
  margin-left: 4px;
  align-items: center;
  column-gap: 4px;
  padding: 2px 8px !important;
  border: 1px solid var(--color-surface-5);

  & > * {
    height: 12px;
  }
}

.bbBBTagsTriggerCustom {
  height: 100%;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.bbBBTagsTriggerToggleSelected {
  background-color: var(--color-primary-200);
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;