.bbBreakdownCard {
  margin: 10px;
  min-height: 620px;

  .bbBreakdownContainer {
    padding: 0 5px;
    text-align: center;

    .bbBreakdownLoading {
      padding-top: 150px;
      padding-bottom: 150px;
      display: flex;
      justify-content: center;
    }

    tspan {
      fill: var(--color-black);
    }

    .bbBreakdownChartContainer {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    svg[data-icon="chart-bar"] {
      transform: rotate(90deg) scaleX(-1);
    }

    div[class*="bbTabHeader--"] {
      font-weight: var(--font-weight-bold);
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background-color: var(--color-grey-100);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: var(--border-radius-sm);
      background-color: var(--color-grey-500);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.bbDownloadButton {
  width: 36px;
  height: 36px;
  justify-content: center;
  padding-top: 20px;
  border: 1px solid var(--color-surface-5);

  &:hover,
  &:active,
  &:focus {
    background: var(--color-white);
    border: 1px solid var(--color-surface-5);
  }

  svg {
    width: 15px;
    height: 15px;
    fill: var(--color-primary-1);
  }
}

.bbBreakdownChartLabel {
  height: 40px;
  text-anchor: start;
  font-size: var(--font-size-lg);
  color: var(--color-black);

  .bbBreakdownChartLabelPostImage {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 8px;
  }

  .bbBreakdownChartLabelContainer {
    overflow: hidden;

    .bbBreakdownChartLabelContent {
      text-align: left;

      span {
        position: relative;
        display: inline-block;
        font-weight: var(--font-weight-bold);

        &:hover {
          color: var(--color-primary-1);
          text-decoration: underline;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 11px;
      }

      .bbPlatformIcon-youtube {
        svg {
          width: 29px;
          height: 29px;
          margin-right: 5px;
        }
      }

      .bbChartLabel {
        display: flex;
        align-items: center;
      }

      .bbDetails {
        bottom: 6px;
        font-size: var(--font-size-sm);
        position: relative;
        color: var(--color-grey-700);

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
          margin-right: 3px;
        }
      }
    }

    .bbBreakdownChartLabelContentPost {
      .bbChartLabel > span {
        font-weight: var(--font-weight-medium);
        color: var(--color-on-surface-subtle-1);
      }
    }
  }
}

@media (max-width: 767px) {
  .bbBreakdownCard {
    min-height: unset;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;