.bbReplyingCommentPreview {
  display: flex;
  padding: 12px;
  min-height: 180px;

  .bbReplyingCommentPostPreview {
    margin-left: 20px;
    display: flex;
    align-items: center;

    &Wrapper {
      position: relative;
      display: flex;
      padding: 0;
    }

    &Platform {
      width: 24px;
      height: 24px;
      margin-right: 8px;

      & > * {
        width: 24px;
        height: 24px;
      }
    }
  }

  &Details {
    display: flex;
    flex-direction: column;
    padding: 2px;
    font-size: var(--font-size-lg);

    &Wrapper {
      display: flex;
      align-items: center;

      .bbReplyingCommentPostAuthor {
        font-weight: bold;
        color: var(--color-grey-700);
      }

      .bbReplyingCommentPostDate {
        font-size: var(--font-size-lg);
        color: var(--color-grey-700);
        font-weight: normal;
      }
    }

    .bbReplyingCommentPostMessage {
      color: var(--color-blue-900);
    }
  }

  .bbContainerSentiment {
    height: 4px;
    width: 340px;
    border-radius: 7px;
    margin-bottom: 6px;
  }

  .bbUsername {
    cursor: help;
    display: inline;
    color: var(--color-grey-900);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
  }

  .bbDateWrapper {
    display: inline;
    margin-left: 4px;
    line-height: 15px;
    font-weight: var(--font-weight-bold);
    color: var(--color-grey-700);
    font-size: var(--font-size-lg);

    .bbCommentDate {
      margin-right: 3px;
    }
  }

  .bbCommentSourceInfo {
    line-height: 15px;
    font-size: var(--font-size-lg);
    color: var(--color-grey-700);

    svg {
      top: 2px;
      width: 12px;
      height: 12px;
      cursor: help;
      margin-left: 3px;
      position: relative;
      fill: var(--color-grey-700);
    }
  }
}

.bbReplyingCommentPreviewLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bbReplyingCommentPostImagePreview {
  margin-right: 12px;
  border-radius: 4px;

  & > * {
    color: var(--color-grey-700);
  }
}

.bbCommentSentimentBg-positive {
  & > * {
    background-color: var(--color-green-600);
  }
}

.bbCommentSentimentBg-negative {
  & > * {
    background-color: var(--color-danger-700);
  }
}

.bbCommentSentimentBg-neutral {
  & > * {
    background-color: var(--color-grey-300);
  }
}

.bbCommentSentimentBg-user_tags {
  & > * {
    background-color: var(--color-blue-900);
  }
}

.bbCommentSentimentBg-universally_harmful {
  & > * {
    background-color: var(--color-danger-700);
  }
}

.bbCommentSentimentBg-sensitive {
  & > * {
    background-color: #ea9999ff;
  }
}

.bbCommentSentimentBg-non_harmful {
  & > * {
    background-color: #bdcae0;
  }
}

.bbCommentSentimentBg-hidden {
  & > * {
    background-color: #383e4bff;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;