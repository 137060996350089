.bbConversationListHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -20px;

  &Title {
    font-size: var(--headline-h5-size);
    font-weight: 400;
    color: var(--color-blue-900);
    font-weight: var(--font-weight-bold);

    &Prefix {
      font-weight: var(--font-weight-medium);

      &::after {
        content: ">";
        color: var(--color-grey-600);
        margin-left: 5px;
      }
    }
  }

  &FilterWrapper {
    display: flex;
    flex-flow: row wrap;

    .bbConversationListHeaderSortSelect {
      box-shadow: var(--shadow-sm-2);
    }

    .bbDownloadCommentsCSV {
      display: flex;
      padding: 8px 11px;
      margin-left: 20px;
      border-radius: var(--border-radius-md);
      align-items: center;
      justify-content: center;
      background-color: var(--color-white);
      border: 1px solid var(--color-surface-5);

      svg {
        width: 13px;
        height: 13px;
        fill: var(--color-primary-1);
      }
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;