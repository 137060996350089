@list-item-spacing: 12px;
@message-card-nest-offset: 40px;

.bbMessageList {
  display: flex;
  flex-direction: column;

  div[class*="bbReplyBoxContainer"] {
    margin-left: @message-card-nest-offset;
  }

  .bbMessageListPrivateContainer,
  .bbMessageListPublicContainer {
    display: flex;
  }
}

.bbMessageListPrivate {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto;
  // Always show the scrollbar
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: var(--color-grey-100);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-sm);
    background-color: var(--color-grey-500);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .bbDMChat {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 20px 0 13px;
  }

  div[class*="bbReplyBoxContainer"] {
    margin-left: 0;
  }
}

.bbMessageVirtualList {
  margin-top: @list-item-spacing;

  .bbMessageListItem:not(:first-child) {
    margin-top: @list-item-spacing;
  }
  .bbMessageListItem {
    margin-right: 2px;
  }

  .bbMessageCardWrapper {
    & + .bbMessageCardClientReply,
    & + div[class*="bbReplyInputContainer"] {
      margin-left: @message-card-nest-offset * 2;
    }
  }
}

.bbMessageCardChild {
  margin-left: @message-card-nest-offset;
}

.bbMessageCardClientReply {
  margin-top: @list-item-spacing;
  margin-left: @message-card-nest-offset;
}

.bbMessageListCount {
  display: block;
  color: var(--color-grey-700);
  font-size: var(--font-size-sm);
  text-align: center;
  margin-top: @list-item-spacing;
}

.bbMessageListHidden {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-100);
  color: var(--color-grey-700);
  height: 56px;
  width: 100%;
  text-align: center;
  font-size: var(--font-size-lg);
  border-radius: var(--border-radius-lg);
  margin-top: 16px;
}

.bbMessageBottomDisclaimer {
  display: flex;
  align-items: center;
  color: var(--color-grey-700);
  width: 100%;
  font-size: var(--font-size-md);
  margin-top: 16px;
}

.bbReplyInfoAlert {
  margin-left: 40px;
  margin-top: 16px;

  .bbPostLinkBtn {
    width: 120px;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

.bbMessageListEndOfChat {
  width: 100%;
}

.bbReplyCapabilityDisabledDisclaimer {
  margin-top: 16px;
}

.bbSendImageError {
  display: flex;
  align-items: center;
  color: var(--color-danger-1);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 150%;
  letter-spacing: 0.13px;
  margin-top: 20px;

  svg {
    height: 16px;
    fill: var(--color-danger-1);
    margin-right: 4px;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;