.bbReplyBoxContainer {
  display: block;

  &Mobile {
    margin-left: 0 !important;
  }
}

.bbReplyInputContainer {
  outline: 0;
  border: none;
  display: flex;
  flex-flow: column;
  position: relative;

  &Header {
    display: flex;
    align-items: center;

    &Mobile {
      margin-block: 10px;
      margin-left: 12px;
    }
  }

  textarea {
    width: 100%;
    border: none;
    resize: none;
    color: var(--color-black);
    box-shadow: none;
    position: relative;
    padding: 16px 16px 5px 16px;
    background-color: var(--color-grey-100);
  }

  :global {
    .ant-input:focus {
      box-shadow: none;
    }
  }

  .bbUserTag {
    top: 15px;
    left: 15px;
    color: var(--color-white);
    height: 26px;
    display: flex;
    font-weight: var(--font-weight-bold);
    list-style: none;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: var(--border-radius-md);
    margin-bottom: 5px;
    position: absolute;
    align-items: center;
    font-size: var(--font-size-sm);
    background: var(--color-blue-900);

    .bbRemoveUserTag:after {
      cursor: pointer;
      font-size: var(--headline-h4-size);
      content: "\00d7";
      margin-left: 4px;
      font-weight: 100;
      display: inline-block;
    }
  }

  .bbReplyInput {
    position: relative;
    padding: 0;
    flex-grow: 1;
    background-color: var(--color-grey-100);

    &Mobile {
      .bbTextAreaWrapper {
        textarea {
          padding-top: 5px;
        }
      }
    }
  }

  .bbReplyInputActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 8px 10px 16px;
    background-color: var(--color-grey-100);

    &Mobile {
      span[class*="bbCursorNotAllowed"] {
        width: 100%;
      }
    }

    :global(.emoji-mart) {
      max-height: 290px;
      bottom: -15px;
    }

    &Left {
      display: flex;
      flex-direction: column;
    }

    &Top {
      margin-top: 12px;
      padding-top: 12px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-surface-5);
    }

    &Bottom {
      display: flex;
      justify-content: space-between;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .bbReplyInputErrorMessage {
    color: var(--color-red-700);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    flex: 1;
  }

  .bbSavedReplyButton {
    gap: 6px;
    border: none;
    display: flex;
    width: max-content;
    font-weight: var(--font-weight-bold);
    margin-left: 17px;
    align-items: center;
    color: var(--color-blue-700);
    background-color: var(--color-white);
    box-shadow: var(--shadow-sm-2);

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-blue-700);
    }
  }

  .bbReplyPlatform {
    display: flex;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    align-items: center;
    color: var(--color-blue-900);

    span {
      margin: 0 5px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 5px;
    }
  }

  .bbAlertWarning {
    margin-top: 10px;
  }
}

.bbAutoNavigationTitle {
  font-weight: bold;
  padding-left: 8px;
  font-size: var(--font-size-lg);
}

.bbAutoNavigationToggle {
  display: inline-flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  font-size: var(--font-size-lg);
  font-weight: 600;
}

.bbUploadFileError {
  display: flex;
  align-items: center;
  color: var(--color-danger-1);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 150%;
  letter-spacing: 0.13px;
  margin-top: 20px;

  svg {
    height: 16px;
    fill: var(--color-danger-1);
    margin-right: 4px;
  }
}

.bbSuggestedReplyAlert {
  margin-top: 16px;
}

.bbTextAreaWrapper {
  position: relative;
}

.bbClearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  padding: 0 6px;
  cursor: pointer;

  &Wrapper {
    position: absolute;
    z-index: 1000;
    height: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-on-surface-subtle-2);
    flex-shrink: 0;
  }

  &:active {
    opacity: 0.5;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;