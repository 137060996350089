.bbReplySharePostPreviewWrapper {
  display: flex;
  align-items: center;
  width: 234px;
  margin-top: 20px;
  margin-left: 16px;
  position: relative;
  border-radius: var(--border-radius-md);
  background: var(--color-white);

  .bbReplySharePostPreviewImageWrapper {
    height: 72px;
    width: 72px;
    min-width: 72px;
    display: flex;
    justify-content: center;
    background-color: var(--color-notApplicable);
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
    margin-right: 20px;

    img {
      height: 100%;
    }
  }

  .bbReplySharePostPreviewTitleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    svg {
      height: 24px;
      width: 24px;
    }

    .bbReplySharePostPreviewTitleTextWrapper {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      .bbReplySharePostPreviewTitle {
        color: var(--color-grey-700);
        font-size: var(--font-size-sm);
        font-style: normal;
        font-weight: 700;
      }

      .bbReplySharePostPreviewSubtitle {
        color: var(--color-grey-700);
        font-size: var(--font-size-sm);
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .bbReplySharePostPreviewDescription {
    color: var(--color-primary-900);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 125px;
  }

  .bbRemoveSharePost {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    position: absolute;
    top: -12px;
    right: -15px;
    border-radius: var(--border-radius-xxl);
    background-color: var(--color-white);

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    svg {
      width: 15px;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;