.bbReplyModal {
  padding: 0;

  &Loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Body {
    overflow: visible;
    padding: 0 !important;
  }

  &Content {
    border-radius: var(--border-radius-md);
  }

  &Loading {
    display: flex;
    padding: 180px 0;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    :global {
      .ant-spin-text {
        margin-left: 4px;
      }
    }
  }

  &RightColumn {
    width: 320px;
    padding: 60px 20px 20px;
    background-color: var(--color-surface-4);
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
    overflow: auto;

    &Mobile {
      width: 100%;
      padding: 20px;
    }

    [class*="bbAccordionBody"] {
      padding: 0;
    }

    .bbNotes {
      overflow: hidden;

      &Title {
        display: flex;
        gap: 8px;
        align-items: center;

        .bbNotesCount {
          padding: 0.5px 5.5px;
          display: flex;
          border-radius: var(--border-radius-xxl);
          background-color: var(--color-surface-4);
          color: var(--color-on-surface-subtle-1);
          text-align: center;
          font-size: var(--font-size-xxs);
          font-weight: var(--font-weight-bold);
        }
      }
    }

    .bbZendeskTicket {
      svg {
        margin-left: 0;
      }
    }

    div[class*="bbAccordionHeader--"] {
      svg {
        fill: var(--color-on-surface-subtle-2);
      }
      div[class*="bbAccordionHeaderLeft--"] {
        svg {
          margin: 0px 8px 0px 0px;
        }
      }
    }
  }

  &ContentWrapper {
    display: flex;
    max-height: 90vh;
    min-height: 610px;

    &Mobile {
      flex-direction: column;
      max-height: fit-content;
    }

    .bbCategoryWrapper {
      flex-direction: column;
      overflow-y: auto;
      max-width: 750px;
      flex: 1;

      .bbCategoryTitle {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        padding: 20px;
        box-shadow: var(--shadow-sm-1);
        background-color: var(--color-white);
        z-index: 1;

        .bbCategoryTitleDefault {
          display: flex;
          align-items: center;
          cursor: help;
        }

        &Text {
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-bold);
        }

        &TextLink {
          cursor: pointer;
          text-decoration: underline;
          font-weight: var(--font-weight-medium);
        }

        &Icon {
          margin-left: 4px;
          display: flex;

          svg {
            fill: var(--color-on-surface-subtle-2);
            width: 16px;
            height: 16px;
          }
        }

        .bbCategoryDetails {
          display: flex;
          align-items: center;
          margin-left: 12px;

          &Icon {
            display: flex;
            margin-right: 12px;

            svg {
              fill: var(--color-on-surface-subtle-2);
              width: 16px;
              height: 16px;
            }
          }

          &Text {
            font-size: var(--font-size-lg);
            font-weight: var(--font-weight-bold);
          }
        }
      }

      .bbCategoryBody {
        padding: 20px;
        height: calc(100% - 62px); // minus header height

        div[class*="bbMessageListPrivateContainer--"] {
          height: 100%;
          justify-content: space-between;
        }
      }
    }

    .bbEmptyDetailsView {
      width: 288px;
      padding: 20px;
      padding-bottom: 0;
      border: 1px solid var(--color-surface-5);
    }
  }

  button[class*="bbModalClose--"] {
    top: 18px;
    right: 18px;
    transform: none;
  }

  .bbUserDetailsMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0;
    width: fit-content;

    .bbAuthorInfo {
      display: flex;
      align-items: center;
      overflow: hidden;

      .bbAuthorTotalConversations {
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-medium);
      }
    }

    .bbAuthorName {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-extrabold);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .bbAuthorNameWithActions {
      max-width: 114px;
    }

    .bbPlatformIcon {
      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .bbAuthorActionsDropdown {
      box-shadow: none;
      border-radius: var(--border-radius-md);
      border: 1px solid var(--color-surface-5);
      width: 32px;
      padding: 7px;
      margin-left: 8px;
    }
  }

  .bbUserDetails {
    width: 260px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-surface-4);
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);

    &Title {
      margin-bottom: 20px;
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-bold);
    }

    &Navigation {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 8px;
    }

    .bbAuthorDetailsCard {
      display: flex;
      flex-direction: column;
      box-shadow: var(--shadow-md-2);

      .bbAuthorDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bbAuthorInfo {
          display: flex;
          overflow: hidden;
        }

        .bbAuthorName {
          font-size: var(--font-size-xl);
          font-weight: var(--font-weight-bold);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .bbAuthorNameWithActions {
          max-width: 114px;
        }

        .bbPlatformIcon {
          svg {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }

        .bbAuthorActionsDropdown {
          box-shadow: none;
          border-radius: var(--border-radius-md);
          border: 1px solid var(--color-surface-5);
          width: 32px;
          padding: 7px;
        }
      }

      .bbAuthorLocation {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: var(--font-size-lg);
        margin-bottom: 4px;
        & svg {
          width: 16px;
          height: 16px;
          fill: #858ea6;
        }
      }

      .bbAuthorTotalReviews {
        font-size: var(--font-size-lg);
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid var(--color-surface-5);

        &Help {
          cursor: help;
          position: relative;
          top: 3px;
          svg {
            width: 14px;
            height: 14px;
            fill: var(--color-grey-600);
            margin-left: 4px;
          }
        }
      }

      .bbAuthorTotalFollowers {
        font-size: var(--font-size-lg);
        padding-bottom: 6px;
        margin-bottom: 6px;
        border-bottom: 1px solid var(--color-grey-200);
      }

      .bbAuthorTotalConversations {
        margin-bottom: 12px;
        font-size: var(--font-size-lg);
      }
    }

    .bbLastUpdated {
      font-size: var(--font-size-xs);
      color: var(--color-grey-700);

      .bbLastUpdatedRefresh {
        cursor: pointer;
        text-decoration: underline;
        font-weight: var(--font-weight-bold);
        color: var(--color-grey-900);
      }
    }
  }
}

.bbReplyModalArrow {
  top: 0;
  height: 100%;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;

  svg {
    width: 24px;
    fill: white;
  }
}

.bbReplyModalArrowLeft {
  left: -40px;
  justify-content: flex-start;
}

.bbReplyModalArrowRight {
  right: -40px;
  justify-content: flex-end;
  transform: rotate(180deg);
}

.bbBlockUserConfirmationModalSecondaryText {
  font-size: var(--font-size-md);
}

.bbApprovalToast {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);

  button {
    width: fit-content;
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;