@image-wrapper-size: 72px;

.bbSharePostSelectorModalItem {
  display: flex;
  align-items: center;
  height: 88px;
  gap: 8px;
  padding: 8px;
  border-radius: var(--border-radius-md);
  background: var(--color-blue-150);
  margin-bottom: 8px;
  cursor: pointer;

  &NoImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 9px;
    background-color: var(--color-surface-5);
    color: var(--color-on-surface-subtle-1);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
  }

  &ImageWrapper {
    height: @image-wrapper-size;
    width: @image-wrapper-size;
    min-width: @image-wrapper-size;
    display: flex;
    justify-content: center;
    background-color: var(--color-white);

    img {
      height: 100%;
      max-width: 54px;
      object-fit: contain;
    }
  }

  .bbSharePostSelectorModalItemTextsWrapper {
    width: calc(100% - 80px);

    .bbSharePostSelectorModalItemTitleWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      svg {
        height: 24px;
        width: 24px;
      }

      .bbSharePostSelectorModalItemTitleTextsWrapper {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        .bbSharePostSelectorModalItemTitle {
          color: var(--color-grey-700);
          font-size: var(--font-size-sm);
          font-style: normal;
          font-weight: 700;
        }

        .bbSharePostSelectorModalItemSubtitle {
          color: var(--color-grey-700);
          font-size: var(--font-size-sm);
          font-style: normal;
          font-weight: 500;
        }
      }
    }

    .bbSharePostSelectorModalItemDescription {
      color: var(--color-blue-900);
      font-size: var(--font-size-sm);
      font-style: normal;
      font-weight: 500;
      height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;