.bbSavedReplyModal {
  left: 60px;

  &Mobile {
    left: 0 !important;
  }
}

&Body {
  min-height: 400px;
  overflow: unset;
}

.bbSavedRepliesTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  margin-bottom: 16px;

  &Title {
    color: var(--color-primary-900);
    font-size: var(--headline-h6-size);
    font-weight: var(--font-weight-extrabold);
  }

  span[class*="bbTriggerIconWithNoTags"] {
    margin-bottom: 8px;
  }

  button[class*="bbBBTagsTriggerDefault"] {
    top: 0;
    height: 25px;
    top: 0;
    line-height: normal;

    span {
      height: 20px;
    }
  }
}

.bbSavedReplyEmptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .bbSavedReplyEmptyCreateButton {
    margin-top: 10px;
    margin-bottom: 20px;

    svg {
      width: 12px;
      height: 12px;
      fill: var(--color-white);
      margin-right: 8px;
    }
  }

  .bbSavedReplyEmptyTitle {
    font-size: var(--headline-h5-size);
    font-weight: var(--font-weight-bold);
    color: var(--color-blue-900);
  }

  .bbSavedReplyEmptyDescription {
    max-width: 360px;
    font-size: var(--font-size-xl);
    color: var(--color-grey-700);
    text-align: center;
  }
}

.bbSavedReplyCreateForm {
  padding: 6px;

  &Input {
    width: 100%;

    &Message {
      width: 100%;
      height: 100px;
    }
  }

  &Actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.bbCommentTagsList {
  & > * {
    display: inline;
  }
}

.bbSavedReplyHeader {
  display: flex;
  margin-top: 8px;
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
  font-size: var(--headline-h3-size);

  &Back {
    padding: 7px;
    margin-right: 8px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-primary-1);
    }
  }
}

@font-family: Manrope;@font-size-base: 16px;@body-background: #f4f9ff;@layout-header-height: 69px;@layout-header-padding: 0px;@layout-header-background: var(--color-white);@layout-header-box-shadow: 0 1px 0 0 #d9d9d9;@layout-header-color: #000;@layout-body-background: #eff2f8;@layout-body-background-2: #e3e7ec;@layout-body-background-3: #96a6c1;@layout-footer-color: var(--color-black);@layout-footer-padding: 8px;@layout-footer-background: var(--color-white);@layout-trigger-height: 48px;@layout-trigger-color: var(--color-white);@layout-trigger-background-light: var(--color-white);@layout-trigger-color-light: var(--color-black);@layout-zero-trigger-width: 36px;@layout-zero-trigger-height: 42px;@layout-sider-background-light: var(--color-white);@layout-sider-background: transparent;@menu-bg: white;@menu-font-size: var(--font-size-lg);@menu-item-padding: 0;@menu-item-divider-color: var(--color-blue-800);@menu-item-group-title-color: var(--color-blue-700);@menu-item-vertical-margin: 0;@menu-popup-bg: var(--color-white);@menu-popup-item-color: var(--color-black);@menu-popup-border-radius: 2px;@menu-popup-item-height: 32px;@menu-popup-item-font-size: var(--font-size-lg);@menu-popup-item-line-height: 1.57;@menu-popup-item-padding: 6px 20px 7px 12px;